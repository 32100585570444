<template>
    <div class="withdarw-page">
        <h1>Select Withdrawal </h1>
        <!-- navidation bar -->
        <div class="topbar">
            <ul>
                <li :class="{ active: selectedTab === 'billPayments' }" @click="selectTab('billPayments')">Bill Payments</li>
            </ul>
            <div class="hl"></div>
        </div>
        <!-- payment form  -->
        <div class="bill-form" v-if="selectedTab === 'billPayments'">

            <div class="select-field" :class="{'red-border': this.Error.Category}">
                <label for="">Select  Category</label>
                <el-select
                    v-model="selectedCategory"
                    placeholder="Category"
                    size="large"
                    style="width: 383px;"
                    @change="getCompany"
                    >
                    <el-option label="Select Category" 
                    value=""/>

                    <el-option
                        v-for="item in Category"
                        :key="item.category_id"
                        :label="item.custom_category"
                        :value="item.category_id"
                    />
                </el-select>
                <p v-if="this.Error.Category" class="text-red text-small">{{ this.Error.Category }}</p>
            </div>
            <div class="select-field" :class="{ 'red-border': this.Error.company }">
                <label for="">Select  Company</label>
                <el-select
                    v-model="selectedCompany"
                    placeholder="Company"
                    size="large"
                    style="width: 383px;"
                    >
                    <el-option
                        v-for="item in Company"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>
                <p v-if="(this.Error.company) && (this.selectedCompany = '')" class="text-red text-small">{{ this.Error.company }}</p>
            </div>
            <div class="select-field" :class="{ 'red-border': this.Error.consumeNum }">
                <label for="">Consumer No</label>
                <el-input
                    v-model="consumeNum"
                    style="width: 383px;"
                    size="large"
                    :placeholder="validations.minConsumerLimit ? 'Enter Minimum '+ validations.minConsumerLimit + ' digits consumer number' : 'Enter Consumer number'"
                    :maxlength="validations.maxConsumerLimit"
                    :minlength="validations.minConsumerLimit"                    
                />
                <p v-if="this.Error.consumeNum" class="text-red text-small">{{ this.Error.consumeNum }}</p>
            </div>
            <div class="select-field" :class="{ 'red-border': this.Error.nickname }">
                <label for="">Nick Name</label>
                <el-input
                    v-model="recipientName"
                    style="width: 383px;"
                    size="large"
                    placeholder="Nick Name"
                    maxlength="20"
                />
                <p v-if="this.Error.nickname" class="text-red text-small">{{ this.Error.nickname }}</p>
            </div>
            <!-- procced button  -->
            <div class="proceed-btn">
                <button  @click="billInquiry()">
                    <span v-if="loading" class="loading-spinner spinner"></span>
                    Proceed
                </button>
            </div>
            <!-- payment dialog  -->
            <el-dialog
                v-model="dialogVisible"
                width="500"
            >
                <div class="dialog-header">Review Details</div>
                <div class="modal-body">
                    <div class="modal-data">
                        <span class="heading">Bill Payment</span>
                        <div>
                            <p>Date</p>
                            <P>{{PaymentDate}}</P>
                        </div>
                    </div>
                    <div class="dash-border"></div>
                    <div class="modal-data">
                        <div>
                            <p>From</p>
                            <p>{{this.userName}}</p>
                        </div>
                        <div style="display:flex; justify-content: end;">
                            <p>{{this.userMobile}}</p>
                        </div>
                    </div>
                    <div class="dash-border"></div>
                    <div class="modal-data">
                        <div>
                            <p>Recipient</p>
                            <p>{{this.recipientName}}</p>
                        </div>
                        <div style="display:flex; justify-content: end;">
                            <p>{{this.consumeNum}}</p>
                        </div>
                    </div>
                    <div class="dash-border"></div>
                    <div class="modal-data">
                        <div>
                            <p>Transaction Amount</p>
                            <p>{{this.transactionAm}}</p>
                        </div>
                        <div>
                            <p>Fee</p>
                            <p>{{this.Latefee}}</p>
                        </div>
                        <div>
                            <p>Total</p>
                            <p>{{totalBill()}}</p>
                        </div>
                    </div>
                    <div class="center-div">
                        <div :class="((Number(wallet) < Number(validations.minAmount)) || (Number(wallet) < (Number(transactionAm) + Number(Latefee)))) ? 'less-than-2000' : 'balance'">
                            <p>Available Balance</p>
                            <p>{{ wallet }}</p>
                        </div> 
                    </div>

                </div>
                <!-- payment dialog footer  -->
                <template #footer>
                <div class="dialog-footer">
                    <el-button v-if="(Number(wallet) < Number(this.validations.minAmount)) || (Number(wallet) < (Number(this.transactionAm) + Number(this.Latefee)))" disabled class="pay-btn" @click="dialogVisible = false">
                        Pay Now
                    </el-button>
                    <el-button v-else class="pay-btn" @click="billPayment">
                        <span v-if="paymentLoading" class="loading-spinner payment-spinner" 
                        ></span>
                        Pay Now
                    </el-button>
                </div>
                </template>
            </el-dialog>
            <!-- payment Successful dialog  -->
            <el-dialog
                v-model="successDialogVisible"
                width="300px"
                class="success-dialog" 
                style="padding: 20px 50px 20px 50px;"
            >
                <div class="text-center">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 103 103" fill="none">
                            <g clip-path="url(#clip0_5046_3211)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 51.5C0 37.8414 5.42588 24.7421 15.084 15.084C24.7421 5.42588 37.8414 0 51.5 0C65.1587 0 78.2579 5.42588 87.916 15.084C97.5741 24.7421 103 37.8414 103 51.5C103 65.1587 97.5741 78.2579 87.916 87.916C78.2579 97.5741 65.1587 103 51.5 103C37.8414 103 24.7421 97.5741 15.084 87.916C5.42588 78.2579 0 65.1587 0 51.5ZM48.5611 73.542L78.2113 36.4757L72.8553 32.1909L47.5723 63.7845L29.664 48.8632L25.2693 54.1368L48.5611 73.542Z" fill="#22CD85"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_5046_3211">
                            <rect width="103" height="103" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </p>
                    <h2 class="success-h">Payment Successful.</h2>
                    <p>Transaction Number: {{transactionNum}}</p>
                    <div class="dash-border" style="border: 1px solid #C7BEBE; border-style: dashed; margin-top: 12px;"></div>
                    <div class="success-dialog-footer" style="display: flex ; justify-content: space-between; padding-top: 14px;">
                        <h3>Amount Paid:</h3>
                        <p>Rs: {{totalBill()}}</p>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import php from "@/php/index.js"
export default {
    name:"withdraw",
    data() {
        return {
            dialogVisible: false,
            paymentDialogVisible:false,
            loading: false,
            paymentLoading: false,
            successDialogVisible: false,
            selectedTab: 'billPayments',
            wallet: 250,
            maxlimit:'',
            userName:'',
            userMobile:'',
            transactionAm: 0,
            Latefee: 0,
            transactionNum: '',
            consumeNum:'',
            Error: {
                consumeNum: '',
                nickname: '',
                company: '',
                Category: '',
            },
            validations: {
                minAmount: '',
                maxAmount: '',
                minConsumerLimit: '',
                maxConsumerLimit: '',
                consumerLabel: '',
            },
            userInfo: {},
            recipientName: '',
            selectedCategory: '',
            selectedCompany: '',
            Category : [],
            Company : [],
        }
    },
    watch:{
        selectedCompany: {
            handler(newValue, oldValue) {
                if (this.Company && this.Company.length > 0) {
                    const company = this.Company.find((company) => company.id == this.selectedCompany)
                    if(this.selectedCategory.length != 0 && this.selectedCompany.length != 0) {
                        this.validations.minAmount = company.min_limit
                        this.validations.maxAmount = company.max_limit
                        this.validations.minConsumerLimit = company.min_consumer_no_limit
                        this.validations.maxConsumerLimit = company.max_consumer_no_limit
                        this.validations.consumerLabel = company.consumer_label
                    }
                } else if(newValue !== oldValue && newValue !== '') {
                    this.selectedCompany = ''
                    this.consumeNum = ''
                    this.recipientName = ''
                    this.Error.Category = ''
                }
                if (newValue) {
                    this.Error.company = ''
                }
                if (this.consumeNum.length < this.validations.minConsumerLimit || this.consumeNum === '') {
                    this.Error.consumeNum = `Consumer number must be at least ${this.validations.minConsumerLimit} digits`
                }
            }
        },
        selectedCategory(newValue,oldValue) {
            if(newValue !== oldValue && newValue !== '') {
                this.selectedCompany = ''
                this.consumeNum = ''
                this.recipientName = ''
            }
            if (newValue) {
                this.Error.Category = ''
            }
        },
        consumeNum(newVal, oldVal) {
            if (newVal !== oldVal && newVal === '') {
                this.recipientName = ''
            }
            if (newVal.length >= this.validations.minConsumerLimit) {
                this.Error.consumeNum = ''
            }
        },
        recipientName(newVal) {
            if (newVal) {
                this.Error.nickname = ''
            }
        }
    },
    computed: {
        isAnyFieldEmpty() {
            return (
                this.selectedCategory === '' ||
                this.selectedCompany === '' ||
                this.consumeNum === '' ||
                this.recipientName === ''
            )
        },
        PaymentDate() {
            const today = new Date()
            const dd = String(today.getDate()).padStart(2, '0')
            const mm = String(today.getMonth() + 1).padStart(2, '0') 
            const yyyy = today.getFullYear()
            const hour = today.getHours()
            const minute = today.getMinutes()
            const ampm = hour >= 12 ? 'PM' : 'AM'
            const formattedHour = hour % 12 || 12
            const formattedMinute = minute.toString().padStart(2, '0') 
            return `${mm}/${dd}/${yyyy}, ${formattedHour}:${formattedMinute} ${ampm}`
        },
    },
    mounted() {
        const userInfo = localStorage.getItem('userInfo')
        this.userInfo = JSON.parse(userInfo) || {}
        this.userName = this.userInfo.nickname
        this.userMobile = this.userInfo.mobile
        this.getCatogery()
        this.generateNumber()
        this.getBalance()
    },
    methods:{
        // genrate random trac number 
        generateNumber(length) {
            let result = ''
            const characters = '0123456789'
            const charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        }, 
        resetValidations() {
            this.validations.minAmount = ''
            this.validations.maxAmount = ''
            this.validations.minConsumerLimit = ''
            this.validations.maxConsumerLimit = ''
            this.validations.consumerLabel = ''
        },
        // count totall Bill 
        totalBill() {
            return Number(this.transactionAm) + Number(this.Latefee)
        },
        // navigation 
        selectTab(tab) {
            this.selectedTab = tab
        },
        // check cuurent date function
        currentDate() {
            const today = new Date()
            const dd = String(today.getDate()).padStart(2, '0')
            const mm = String(today.getMonth() + 1).padStart(2, '0') 
            const yyyy = today.getFullYear()
            const hour = today.getHours()
            const minute = today.getMinutes()
            const seconds = today.getSeconds() 
            return `${yyyy}${mm}${dd}${hour}${minute}${seconds}`
        },
        // get catogery Api 
        getCatogery() {
            php.get('services/zindagi/get-categories').then((response) => {
                console.log('response:', response)
                this.Category = response.data.data
            }).catch((error) => {
                console.log('error:', error)
            })
        },
        // dialogOpen
        paymentDialog(e) {
            e.preventDefault()
            this.paymentDialogVisible = true
        },
        // get Company Api
        getCompany() {
            php.get(`/services/zindagi/get-billers/${this.selectedCategory}`).then((response) => {
            this.Company = response.data.data
            const maxLimits = this.Company.map(item => item.max_consumer_no_limit)
            this.maxlimit = maxLimits
            }).catch((error) => {
                console.log('error:', error)
            })
        },
        ErrorReset() {
            this.Error.Category = ''
            this.Error.company = ''
            this.Error.consumeNum = ''
            this.Error.nickname = ''
        },
        // Billing Inquiry Api
        billInquiry() {
            this.Error = {
                consumerNum: '',
                nickname: '',
                company: '',
                Category: '',
            }
            if (this.selectedCategory === '') {
                this.Error.Category = 'Category is required'
            }

            if (this.selectedCompany === '') {
                this.Error.company = 'Company is required'
            }

            if (this.consumeNum.length < this.validations.minConsumerLimit || this.consumeNum === '') {
                this.Error.consumeNum = (`Consumer number must be at least ${this.validations.minConsumerLimit} digits`)
            }            
            if (this.recipientName === '') {
                this.Error.nickname = 'Nickname is required'
            }

            if (this.Error.Category || this.Error.company || this.Error.consumeNum || this.Error.nickname) {
                return
            }
            this.ErrorReset()
            this.loading = true
            const randomNumbers = this.generateNumber(6) // Generates a random 6-digit number string
            const dateTime = this.currentDate()
            const inquiryPayload = {
                BillInquiryRequest: {
                    MerchantType: '0088',
                    TraceNo: randomNumbers,
                    CompanyName: 'NOVA',
                    DateTime: dateTime,
                    MobileNo: this.userInfo.mobile,
                    TerminalId: 'NOVA',
                    ProductId: '60035',
                    ConsumerNo: this.consumeNum,
                    Amount: '970',
                    Reserved1: '01'
                }
            }
            php.post('/services/zindagi/billing-inquiry', inquiryPayload).then((response) => {
                console.log('response here: ', response)
                if(response.data.code == 200) {
                    this.dialogVisible = true
                    this.transactionAm = response.data.data.Amount
                    this.Latefee = response.data.data.LateBillAmount
                    this.loading = false
                }else if(response.data.code == 422) {
                    this.Error.consumerNumber = response.data.data.message
                    this.loading = false
                }
            }).catch((error) => {
                console.log("error:", error)
                this.loading = false
            })
        },
        // Bill payment Api 
        billPayment() {
            this.paymentLoading = true
            const dateTime = this.currentDate()
            const randomNumbers = this.generateNumber(6)
            const totalPayment = Number(this.transactionAm) + Number(this.Latefee)
            const PayPayload = {
                    BillPaymentRequest: {
                    user_id: this.userInfo.userId,
                    MerchantType: "0088",
                    TraceNo: randomNumbers,
                    CompanyName: "NOVA",
                    DateTime: dateTime,
                    MobileNo: this.userInfo.mobile,
                    TerminalId: "NOVA",
                    OtpPin: "01",
                    ProductId: "60035",
                    ConsumerNo: this.consumeNum,
                    Amount: totalPayment,
                    Reserved1: "01",
                    Reserved2: ""
                }
            }
            php.post('services/zindagi/bill-payment', PayPayload).then((response) => {
                if(response.data.code == 200) {
                    this.transactionNum = response.data.data.TraceNo
                    this.successDialogVisible = true
                    this.dialogVisible = false
                    this.paymentLoading = false
                    setTimeout(() => {
                        window.location.href = "/userwallet"
                    },2000)

                }else {
                    this.paymentLoading = false
                }
            }).catch((error) => {
                console.log("error:", error)
                this.paymentLoading = false
            })
        },
        // Check logged User balance Api 
        getBalance() {
            php.get(`wallet/get?user_id=${this.userInfo.userId}`).then((response) => {
                if(response.data.code == 404) {
                    // this Api will Call when User did not have Any Wallet 
                    this.walletCreate()
                }else{
                    this.wallet = response.data.data.CurrentBalance
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        // Create Wallet Api 
        walletCreate() {
            const payload = {
                user_id: this.userInfo.userId,
                password: "123456",
                password_confirm: "123456"
            }
            php.post('wallet/create', payload).then((response) => {
                console.log('response:', response)
                this.getBalance()
            }).catch((error) => {
                console.log('error:', error)
            })
        }
    }
  }
</script>
<style lang="less">
    .withdarw-page{
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0px;
        .red-border  {
            border-color: red;
        }

        .text-red {
            color: red;
        }
        .disable{
            cursor: not-allowed;
            pointer-events: none;
        }
        >h1{
            font-size: 24px;
            font-weight: 500;
            line-height: 33.26px;
        }
        .topbar{
            padding: 35px 0px;
            ul{
                display: flex;
                flex-direction: row;
                li{
                    padding: 19px 50px 19px 19px;
                    border-width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    color: #5F5E5EA3;
                }
                .active{
                    color: #000;
                    border-bottom: 2px solid #000;
                    background-color: #7A3FF305;
                }
            }
            .hl{
                width: 383px;
                border: 1px solid #C9C6C636;
            }
        }
        .bill-form{
            display: flex;
            flex-direction: column;
            gap: 23px;
        }
        .select-field{
            display: flex;
            flex-direction: column;
            label{
                font-size: 16px;
                font-weight: 400;
                line-height: 18.32px;
                padding-bottom: 10px;
                color: #2B2B3F;
            }
            span{
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #757575;
            }
        }
        .proceed-btn{
            margin-top: 20px;
            button{
                width: 135px;
                height: 40px;
                padding: 10px 13px;
                gap: 10px;
                border-radius: 50px;
                opacity: 0px;
                background: #000;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.4px;
            }
            
        }
        .el-select--large .el-select__wrapper, .el-input--large .el-input__wrapper {
            border: 1px solid #C4C4C4;
            padding: 11px 10px 12px 26px !important;
            width: 383px !important;
            height: 40px !important;
            border-radius: 3px !important;
            opacity: 0px;
            box-shadow: none !important;
        }
        .red-border .el-select--large .el-select__wrapper,
        .red-border .el-input--large .el-input__wrapper {
            border: 1px solid rgb(255, 0, 0);
        }
        .el-select__wrapper {
            box-shadow: none !important;
        }
        .el-dialog {
            --el-dialog-width: 450px !important;
            border-radius: 23px !important;
            .dialog-header{
                font-size: 18px;
                font-weight: 500;
                line-height: 29.6px;
                color: #363636;
                text-align: center;
                padding-top: -14px;
            }
            .el-dialog__headerbtn .el-dialog__close {
                color: #535353 !important;
                font-size: 9px !important;
            }
            .el-dialog__headerbtn {
                cursor: pointer;
                font-size: 10px !important;
                height: 15px !important;
                padding: 3px -3px !important;
                right: 17px !important;
                top: 17px !important;
                width: 15px !important;
                border: 1.28px solid #535353 !important;
                border-radius: 50% !important;
            }
            .modal-body{
                padding: 20px 50px 20px 50px;
                .modal-data{
                    padding-bottom: 8px;
                    div{
                        display: flex;
                        justify-content: space-between;
                        p{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 11.76px;
                            color: #000000;
                            padding: 6px 0px;
                        }
                    }
                }
                .center-div{
                    display: flex;
                    justify-content: center;
                    padding-top: 12px;
                    .balance{
                        width: 100% !important;
                        height: 24px;
                        border-radius: 4px;
                        opacity: 0px;
                        border: 0.5px solid #0C5CA8;
                        background: #8ECEFD6E;
                        display: flex;
                        justify-content: space-between;
                        padding: 6px 10px;
                        p{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 9.76px;
                            color: #000000;
                        }
                    }
                    .less-than-2000{
                        width: 100% !important;
                        height: 24px;
                        border-radius: 4px;
                        opacity: 0px;
                        background: #FF000057;
                        border: 0.5px solid #FF0000;
                        display: flex;
                        justify-content: space-between;
                        padding: 6px 10px;
                        p{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 9.76px;
                            color: #000000;
                        }
                    }
                }
                .dash-border{
                    border: 1px solid #C7BEBE;
                    border-style: dashed;
                }
                .heading{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 12.14px;
                    color: #000000;
                    padding-bottom: 10px;
                }
            }
            .dialog-footer{
                    display: flex !important;
                    justify-content: center !important;
                .pay-btn{
                    width: 125px;
                    height: 35px;
                    gap: 10px;
                    border-radius: 43px;
                    opacity: 0px;
                    background: #000;
                    color: #FFFFFF;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.4px;
                }
            }
            .success-h{
                font-size: 24px;
                font-weight: 600;
                line-height: 43px;
                color: #22CD85;
            }
            .text-center{
                p{
                    color: #5F5E5EA3;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 27px;
                }
            }
            .success-dialog-footer{
                h3{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 36px;
                    color: #222222;  
                }
                p{
                    color: #5F5E5E;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 36px;  
                }      
            }
        }
        .loading-spinner {
            position: relative;
            display: inline-block;
        }

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
    .spinner:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      margin-top: -12px;
      margin-left: -14px;
      border-radius: 50%;
      border: 2px solid white;
      border-top-color: #333;
      animation: spinner 1s linear infinite;
    }
    .payment-spinner::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      margin-top: -6px;
      margin-left: -17px;
      border-radius: 50%;
      border: 2px solid white;
      border-top-color: #333;
      animation: spinner 1s linear infinite;
    }
    .paymentDialog {
        .payModal-body{
            padding-top: 26px;
            .el-input--large .el-input__wrapper {
                padding: 11px 10px 12px 26px !important;
                width: 100% !important;
                height: 53px !important;
                border-radius: 4px !important;
                opacity: 0px;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                border: 1.45px solid #98989999
            }
            .modal-field{
                padding-bottom: 12px;
            }
            .dflex-between{
                display: flex;
                justify-content: space-between;
                padding: 18px 0;
                .paymentOperator{
                    background: #FBFAFF;
                    padding: 15px;
                }
                .paymentOperator:active{
                    background: #FBFAFF;
                    padding: 15px;
                    border: 1px solid #000;
                }
            }
            .dflex-around{
                display: flex;
                justify-content: space-around;
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23.76px;
                    color: #000;
                }
            }
        }
        .dialog-footer{
            display: flex !important;
            justify-content: center !important;
            margin-top: 12px;
            .Proceed-btn{
                width: 145px;
                height: 35px;
                border-radius: 50px;
                opacity: 0px;
                background: #000;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.4px;
            }
        }
    }
  }
</style>
